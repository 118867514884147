<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Fulfillment') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'skuMaster' }" >
          {{ $t('SKU Master') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'skuMaster' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>

    <!-- save -->
    <b-button
      variant="info"
      class="ll-save"
      @click="saveSku"
    >
      <span>{{ $t('Save') }}</span>
    </b-button>

    <!-- first floor -->
    <b-row>
      <b-col
        lg="9"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-hei ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('General') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Customer')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.customer"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Creation Time')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.createdAt"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Last update time')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.updatedAt"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Item Code')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemCode"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Ref code')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemRefCode"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('KEC product code')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.productID"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Name')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemName"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Title')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemTitle"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Description')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemDesc"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Type')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.itemType"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Brand')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.brand"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Manuafacturer')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.manufacturer"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Stock unit')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.unit"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Pack factor')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.packFactor"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Hazmat item')"
                  label-for="s"
                  class="ll-bform"
                >
                  <div class="ll-radioBox">
                    <b-form-radio
                      name="some-radios"
                      v-model="baseInfo.isHazmat"
                      value=true
                    >
                      {{ $t('Yes') }}
                    </b-form-radio>
                    <b-form-radio
                      name="some-radios"
                      v-model="baseInfo.isHazmat"
                      value=false
                    >
                      {{ $t('No') }}
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Category ID')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.category"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Category name')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.categoryName1"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Fragile item')"
                  label-for="s"
                  class="ll-bform"
                >
                  <div class="ll-radioBox">
                    <b-form-radio
                      name="some-radios1"
                      value=true
                      v-model="baseInfo.isFragile"
                    >
                      {{ $t('Yes') }}
                    </b-form-radio>
                    <b-form-radio
                      name="some-radios1"
                      value=flase
                      v-model="baseInfo.isFragile"
                    >
                      {{ $t('No') }}
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Sub category ID')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.categoryName2"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Sub category name')"
                  label-for="s"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.categoryName3"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Incloud battery')"
                  label-for="s"
                  class="ll-bform"
                >
                  <div class="ll-radioBox">
                    <b-form-radio
                      name="some-radios2"
                      value=true
                      v-model="baseInfo.includeBattery"
                    >
                      {{ $t('Yes') }}
                    </b-form-radio>
                    <b-form-radio
                      name="some-radios2"
                      value=false
                      v-model="baseInfo.includeBattery"
                    >
                      {{ $t('No') }}
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-hei ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('Measurement') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              :label="$t('Length')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input v-model="baseInfo.length"/>
            </b-form-group>
            <b-form-group
              :label="$t('Width')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input />
            </b-form-group>
            <b-form-group
              :label="$t('Height')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input v-model="baseInfo.height"/>
            </b-form-group>
            <b-form-group
              :label="$t('Net weight')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input v-model="baseInfo.width"/>
            </b-form-group>
            <b-form-group
              :label="$t('Gross weight')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input v-model="baseInfo.grossWeight"/>
            </b-form-group>
            <b-form-group
              :label="$t('Volumn')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input v-model="baseInfo.volumn"/>
            </b-form-group>
            <b-form-group
              :label="$t('Barcode')"
              label-for="s"
              class="ll-bform"
            >
              <b-form-textarea trim v-model="baseInfo.barcode"/>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- second floor -->
    <b-row>
      <b-col
        lg="9"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('Customs clearance') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Description')"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.cusDesc"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Unit price')+'*'"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Currency')+'*'"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('HSCode')"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.hsCode"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('HScode country')"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.hsCodeCountry"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Country of origin')"
                  label-for="t"
                  class="ll-bform"
                >
                  <b-form-input v-model="baseInfo.coo"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('Shelf life management') }}</b-card-title>
            <b-form-checkbox
              v-model="checked1"
              class="custom-control-info"
              name="check-button"
              switch
              @input="onCheck1"
            />
          </b-card-header>
          <b-card-body>
            <b-form-group
              :label="$t('Rotation order')"
              label-for="t"
              class="ll-bform"
            >
              <b-form-input :readonly="readonly1" v-model="baseInfo.rotationOrder"/>
            </b-form-group>
            <b-form-group
              :label="$t('Shelf life(days)')"
              label-for="t"
              class="ll-bform"
            >
              <b-form-input :readonly="readonly2" v-model="baseInfo.shelfLife"/>
            </b-form-group>
            <b-form-group
              :label="$t('Off shelf life(days)')"
              label-for="t"
              class="ll-bform"
            >
              <b-form-input :readonly="readonly3" v-model="baseInfo.offShelfLife"/>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- third floor -->
    <b-row>
      <b-col
        lg="9"
        class="ll-p2"
      >
      </b-col>
      <b-col
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('Serial No mgt') }}</b-card-title>
            <b-form-checkbox
              v-model="checked"
              class="custom-control-info"
              name="check-button"
              switch
              @input="onCheck"
            />
          </b-card-header>
          <b-card-body>
            <b-form-group
              :label="$t('SN format')+'*'"
              label-for="s"
              class="ll-bform"
            >
              <b-form-textarea trim :readonly="readonly" v-model="baseInfo.snFormat"/>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton, BImg,
  BCardHeader, BCardTitle, BCardBody,
  BBreadcrumb,
  BBreadcrumbItem, BFormRadio, BBadge, BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BFormRadio,
    BBadge,
    BFormTextarea,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      isEnabled: true,
      tit1: 'SKU100018213',
      tit2: 'Logistics Wireless mouse',
      tit3: 'Model MS550 with wireless charging dock',
      readonly: false,
      readonly1: false,
      readonly2: false,
      readonly3: false,
      checked: false,
      checked1: false,
      baseInfo: {
        customer: '',
        createdAt: '',
        updatedAt: '',
        itemCode: '',
        itemRefCode: '',
        productID: '',
        itemName: '',
        itemTitle: '',
        itemDesc: '',
        itemType: '',
        brand: '',
        manufacturer: '',
        unit: '',
        packFactor: '',
        category: '',
        categoryName1: '',
        isHazmat: '',
        isFragile: '',
        includeBattery: '',
        cusDesc: '',
        hsCode: '',
        hsCodeCountry: '',
        length: '',
        width: '',
        height: '',
        netWeight: '',
        grossWeight: '',
        volumn: '',
        barcode: '',
        rotationOrder: '',
        shelfLife: '',
        offShelfLife: '',
        coo: '',
        snFormat: '',
        categoryName2: '',
        categoryName3: '',
        ownerCode: '',
      },
    }
  },
  created() {},
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$http.get('skuMaster/getDetail', { params: { id: this.$route.params.id } }).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.baseInfo = info
        }
      })
    },
    onCheck() {
      if (this.checked) {
        this.readonly = true
      } else {
        this.readonly = false
      }
    },
    onCheck1() {
      if (this.checked1) {
        this.readonly1 = true
        this.readonly2 = true
        this.readonly3 = true
      } else {
        this.readonly1 = false
        this.readonly2 = false
        this.readonly3 = false
      }
    },
    saveSku() {
      this.$http.put('skuMaster/save', this.baseInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'skuMaster' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.ll-p2{
  padding: 0.5rem;
}
.ll-radioBox{
  display: flex;
  justify-content: space-around;
}
.ll-bform1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-cancel{
  top: 6rem;
}
.ll-hei{
  height: 530px;
}
.ll-6{
  margin-bottom: 0px;
}
.ll-box{
  width: 100%;
  display: flex;
  height: 230px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.ll-three{
  display: flex;
  justify-content: flex-end;
}
.tit1{
  font-weight: bold;
}
.tit2{
  font-weight: bold;
  font-size: 18px;
}
.tit3{
  font-size: 16px;
}
.ll-save{
  position: relative;
  left: 95%;
}
</style>
<style>
[dir] label {
  width: unset;
}
</style>
